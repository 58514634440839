<template>
<div class="row">
  <div class="col-12 text-center mt-5">
    <h1>Coming Soon</h1>
    </div>
</div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>