<template>
    <div class="Topbar">
        <TopBar/>
    </div>
    <div class="navbar">
        <Layout/>
    </div>
    <div class="content">
      <HelloWorld msg="Welcome to Your Vue.js App"/>
    </div>
</template>

<script>
// @ is an alias to /src
import Layout from '@/components/Layout.vue'
import TopBar from '@/components/TopBar.vue'
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  beforeCreate :function(){
    document.body.className = '';
  },
  name: 'HomeView',
  components: {
    HelloWorld,
    TopBar,
    Layout
  }
}
</script>
