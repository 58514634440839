<template>
    <div class="nav_outer">
        <div class="row nav_inside">
            <div class="col-12 text-center">
                <img alt="Company logo" class="img-fluid comp_logo" height="100" width="100" src="../assets/logo.webp">
            </div>
            <div class="col-12 text-center text-light">
                <div class="width_95">
                    <hr class="white_back">
                </div>
                <p class="margin_0 username_p">{{ user_name }}&nbsp;&nbsp;&nbsp;
                    <span v-if="!show_sub_menu" v-on:click="drop" class="drop_icon">
                        <fa icon="angle-down" />
                    </span>
                    <span v-if="show_sub_menu" v-on:click="drop" class="drop_icon">
                        <fa icon="angle-up" />
                    </span>
                </p>
                <p class="margin_0">{{ user_role_name }}</p>
                <div class="sub-menu" v-if="show_sub_menu">
                    <button class="btn logout_btn text-danger mt-2" v-on:click="log_out">
                        <fa icon="power-off" /> Logout
                    </button>
                </div>
                <div class="width_95">
                    <hr class="white_back">
                </div>
            </div>
            <div class="col-12">
                <ul class="sidebar_ul">
                    <li v-if="canviewdashboard"><router-link to="/dashboard">
                            <p>
                                <fa icon="chart-pie" />&nbsp;&nbsp;Dashboard
                            </p>
                        </router-link></li>
                    <li v-if="canviewproperties">
                        <p style="cursor:default;">
                            <fa icon="city" />&nbsp;&nbsp;Properties
                        </p>
                        <ul class="sub_menu">
                            <li v-if="canviewunits">
                                <router-link to="/properties">
                                    <p>
                                        <fa icon="building" />&nbsp;&nbsp;Properties
                                    </p>
                                </router-link>
                            </li>
                            <li v-if="canviewtenants">
                                <router-link to="/units">
                                    <p>
                                        <fa icon="house" />&nbsp;&nbsp;Units
                                    </p>
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li v-if="canviewtenants"><router-link to="/tenants">
                            <p>
                                <fa icon="user-tie" />&nbsp;&nbsp;Tenants
                            </p>
                        </router-link>
                    </li>
                    <li v-if="canviewservicepros"><router-link to="/serviceproviders">
                            <p>
                                <fa icon="user-tie" />&nbsp;&nbsp;Service Providers
                            </p>
                        </router-link>
                    </li>

                    <li v-if="canviewaccounting"><router-link to="/accounting">
                            <p>
                                <fa icon="dollar" />&nbsp;&nbsp;Accounting
                            </p>
                        </router-link></li>
                    <li>
                        <p style="cursor:default;">
                            <fa icon="user" />&nbsp;&nbsp;My Account
                        </p>
                        <ul class="sub_menu">
                            <li v-if="canviewmyplans"><router-link to="/myplan">
                                    <p>
                                        <fa icon="award" />&nbsp;&nbsp;My Plan
                                    </p>
                                </router-link></li>
                            <li v-if="canviewaccounting"><router-link to="/profile">
                                    <p>
                                        <fa icon="address-card" />&nbsp;&nbsp;Profile
                                    </p>
                                </router-link></li>
                        </ul>
                    </li>
                    <li v-if="canviewusermanagement">
                        <p style="cursor:default;">
                            <fa icon="screwdriver-wrench" />&nbsp;&nbsp;Administrator
                        </p>
                        <ul class="sub_menu">
                            <li v-if="canviewplansandpricings"><router-link to="/plans">
                                    <p>
                                        <fa icon="award" />&nbsp;&nbsp;Plans & Pricing
                                    </p>
                                </router-link></li>

                            <li v-if="canviewusermanagement"><router-link to="/usermanagement">
                                    <p>
                                        <fa icon="user-gear" />&nbsp;&nbsp;User Management
                                    </p>
                                </router-link></li>
                            <li v-if="canviewpermisions"><router-link to="/permissions">
                                    <p>
                                        <fa icon="shield-halved" />&nbsp;&nbsp;Permissions
                                    </p>
                                </router-link></li>
                            <li v-if="canviewlookups"><router-link to="/lookups">
                                    <p>
                                        <fa icon="diagram-project" />&nbsp;&nbsp;Lookups
                                    </p>
                                </router-link></li>
                        </ul>
                    </li>
                </ul>

            </div>
            <div class="col-12">
            </div>
        </div>
    </div>
</template>


<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
    setup() {
        const toast = useToast()
        return { toast }
    },
    data() {
        return {
            user_name: '',
            user_role_name: '',
            user_role_id: 0,
            show_sub_menu: false,

            canviewdashboard: false,
            canviewproperties: false,
            canviewusermanagement: false,
            canviewpermisions: false,
            canviewlookups: false,
            canviewtenants: false,
            canviewunits: false,
            canviewaccounting: false,
            canviewplans: false,
            canviewmyplans: false,
            canviewcontacts: false,
            canviewservicepros: false,
            canviewplansandpricings: false,

            windowHeight: window.innerHeight
        }
    },

    created() {
        window.addEventListener("resize", this.onResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },


    methods: {
        log_out: function () {
            localStorage.removeItem("rent-user-ocl");
            localStorage.removeItem("rent-user-my_menus");
            window.location.href = "/login";
        },
        drop: function () {
            this.show_sub_menu = this.show_sub_menu ? false : true;
        },

        onResize() {
            $(".sidebar_ul").css("height", (window.innerHeight - 250) + "px");
        }
    },
    mounted() {

        $(".sidebar_ul").css("height", (this.windowHeight - 250) + "px");

        let my_menus_json = localStorage.getItem("rent-user-my_menus");
        let my_menus = JSON.parse(my_menus_json);
        if (my_menus != null) {
            if (my_menus.includes("canviewdashboard")) {
                this.canviewdashboard = true;
            }
            if (my_menus.includes("canviewproperties")) {
                this.canviewproperties = true;
            }
            if (my_menus.includes("canviewusermanagement")) {
                this.canviewusermanagement = true;
            }
            if (my_menus.includes("canviewpermisions")) {
                this.canviewpermisions = true;
            }
            if (my_menus.includes("canviewlookups")) {
                this.canviewlookups = true;
            }
            if (my_menus.includes("canviewtenants")) {
                this.canviewtenants = true;
            }
            if (my_menus.includes("canviewunits")) {
                this.canviewunits = true;
            }
            if (my_menus.includes("canviewaccounting")) {
                this.canviewaccounting = true;
            }
            if (my_menus.includes("canviewplansandpricings")) {
                this.canviewplans = true;
            }
            if (my_menus.includes("canviewmyplans")) {
                this.canviewmyplans = true;
            }
            if (my_menus.includes("canviewcontacts")) {
                this.canviewcontacts = true;
            }
            if (my_menus.includes("canviewservicepros")) {
                this.canviewservicepros = true;
            }
            if (my_menus.includes("canviewplansandpricings")) {
                this.canviewplansandpricings = true;
            }
        }

        let user_str = localStorage.getItem("rent-user-ocl");
        let user = JSON.parse(user_str);
        this.user_name = user.isCompany ? user.company_name : user.firstName + " " + user.lastName;
        this.user_role_name = user.role_name;
        this.user_role_id = user.role_id;
        var dom = this;
        $.ajax({
            type: "POST",
            url: this.api_url + "/permission/getUserPermissions",
            timeout: 0,
            dataType: "json",
            contentType: "application/json",
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${user.token}`
            },
            success: function (result) {
                localStorage.setItem("rent-user-my_menus", JSON.stringify(result.data));
                if (result.data.includes("canviewdashboard")) {
                    dom.canviewdashboard = true;
                } else {
                    dom.canviewdashboard = false;
                }

                if (result.data.includes("canviewproperties")) {
                    dom.canviewproperties = true;
                } else {
                    dom.canviewproperties = false;
                }

                if (result.data.includes("canviewusermanagement")) {
                    dom.canviewusermanagement = true;
                } else {
                    dom.canviewusermanagement = false;
                }

                if (result.data.includes("canviewpermisions")) {
                    dom.canviewpermisions = true;
                } else {
                    dom.canviewpermisions = false;
                }

                if (result.data.includes("canviewlookups")) {
                    dom.canviewlookups = true;
                } else {
                    dom.canviewlookups = false;
                }

                if (result.data.includes("canviewtenants")) {
                    dom.canviewtenants = true;
                } else {
                    dom.canviewtenants = false;
                }

                if (result.data.includes("canviewunits")) {
                    dom.canviewunits = true;
                } else {
                    dom.canviewunits = false;
                }

                if (result.data.includes("canviewaccounting")) {
                    dom.canviewaccounting = true;
                } else {
                    dom.canviewaccounting = false;
                }

                if (result.data.includes("canviewplansandpricings")) {
                    dom.canviewplansandpricings = true;
                } else {
                    dom.canviewplansandpricings = false;
                }
                if (result.data.includes("canviewmyplans")) {
                    dom.canviewmyplans = true;
                } else {
                    dom.canviewmyplans = false;
                }
                if (result.data.includes("canviewcontacts")) {
                    dom.canviewcontacts = true;
                } else {
                    dom.canviewcontacts = false;
                }
                if (result.data.includes("canviewservicepros")) {
                    dom.canviewservicepros = true;
                } else {
                    dom.canviewservicepros = false;
                }
            },
            error: function (error) {
                dom.log_out();
            }
        });
    }
}
</script>

<style>
.navbar ul {
    list-style-type: none;
    padding: 0px;
}

.navbar ul li {
    margin-top: 10px;
    /* display: flex; */
    cursor: pointer;
}

.navbar ul li a {
    border-radius: 5px;
    width: 95%;
    color: #fff;
    font-weight: 600;
}

.navbar ul li p {
    padding: 5px 10px;
    border-radius: 5px;
    width: 95%;
    color: #fff;
    font-weight: 600;
    margin-bottom: 1px;
}

.navbar ul li a p:hover {
    text-decoration: none;
    background-color: #f0f4f8;
    color: #000;
}

.router-link-active p {
    background-color: #c5ffc9;
    color: #000 !important;
}

.content {
    padding: 40px 15px 0 15px;
}

.drop_icon {
    font-size: larger;
    color: #fff;
    cursor: pointer;
}

.logout_btn {
    background-color: #ffeaea !important;
    border-radius: 30px !important;
}

.logout_btn:hover {
    color: white !important;
    background-color: red !important;
    border-radius: 30px !important;
}

.sub_menu {
    margin-left: 30px;
}

.sidebar_ul {
    overflow: auto;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c54b98;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #040F57;
}
</style>
