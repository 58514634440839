import { createApp } from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import App from "./App.vue";
import router from "./router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

library.add(fas);
// library.add(fal);

const app = createApp(App)
  .component("fa", FontAwesomeIcon)
  .component("Datepicker", Datepicker)
  .component("vue-phone-number-input", VuePhoneNumberInput);

const options = {
  // You can set your default options here
};
app.config.globalProperties = {
  api_url: "https://rentapi.oceanic.net.fj",
  // api_url: "http://localhost:4000",
};

app.use(Toast, options);
app.use(VueSweetalert2);

app.use(router).mount("#app");
