import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import LoginView from '../views/LoginView.vue'
import "jquery/src/jquery.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/stylesheets/style.css";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/properties",
    name: "properties",
    component: () => import("../views/Properties.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/verify_email/:id/:token",
    name: "verify_email",
    component: () => import("../views/VerifyEmailView.vue"),
  },
  {
    path: "/usermanagement",
    name: "usermanagement",
    component: () => import("../admin_views/UserManagement.vue"),
  },
  {
    path: "/permissions",
    name: "permissions",
    component: () => import("../admin_views/permissions.vue"),
  },
  {
    path: "/lookups",
    name: "lookups",
    component: () => import("../admin_views/Lookups.vue"),
  },
  {
    path: "/units",
    name: "units",
    component: () => import("../views/Units.vue"),
  },
  {
    path: "/tenants",
    name: "tenants",
    component: () => import("../views/Tenants.vue"),
  },
  {
    path: "/lease/:unit_id/:prop_id",
    name: "lease",
    component: () => import("../views/Lease.vue"),
  },
  {
    path: "/Accounting",
    name: "accounting",
    component: () => import("../views/Accounting.vue"),
  },
  {
    path: "/singletenant/:tenant_id?/:open?",
    name: "singletenant",
    component: () => import("../views/SingleTenant.vue"),
  },
  {
    path: "/returndeposit",
    name: "returndeposit",
    component: () => import("../views/ReturnDeposit.vue"),
  },
  {
    path: "/applycredit",
    name: "applycredit",
    component: () => import("../views/ApplyCredit.vue"),
  },
  {
    path: "/addTenant",
    name: "addTenant",
    component: () => import("../views/AddTenant.vue"),
  },
  {
    path: "/singleaccounting/:id?",
    name: "singleaccounting",
    component: () => import("../views/SingleAccounting.vue"),
  },
  {
    path: "/addProperty",
    name: "addProperty",
    component: () => import("../views/AddProperty.vue"),
  },
  {
    path: "/viewProperty/:property_id?",
    name: "viewProperty",
    component: () => import("../views/ViewProperty.vue"),
  },
  {
    path: "/singleUnit/:unit_id?/:property_id?",
    name: "singleUnit",
    component: () => import("../views/SingleUnit.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/plans",
    name: "plans",
    component: () => import("../admin_views/Plans.vue"),
  },
  {
    path: "/myplan",
    name: "myplan",
    component: () => import("../views/MyPlans.vue"),
  },
  {
    path: "/reset_password/:id/:token",
    name: "reset_password",
    component: () => import("../views/Reset_PasswordView.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/Notifications.vue"),
  },
  {
    path: "/serviceproviders",
    name: "serviceproviders",
    component: () => import("../views/ServiceProvidersView.vue"),
  },
  {
    path: "/addserviceprovider",
    name: "addserviceprovider",
    component: () => import("../views/AddServiceProvider.vue"),
  },
  {
    path: "/singleserviceprovider/:service_pro_id?",
    name: "singleserviceprovider",
    component: () => import("../views/SingleServiceProvider.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/singleLease/:lease_id",
    name: "singleLease",
    component: () => import("../views/SingleLease.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  let user = localStorage.getItem("rent-user-ocl");
  if (
    to.name !== "login" &&
    to.name !== "verify_email" &&
    to.name !== "reset_password" &&
    !user
  ) {
    router.push("/login");
  }
  return true;
});

export default router;
