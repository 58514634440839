<template>
    <div class="row font_20 pad_10">
        <div class="col-4">
            <span class="pad_right_20 pointer" v-on:click="open_menu">
                <fa icon="bars" />
            </span>
            <router-link to="/notifications" class="text-light">
                <fa icon="bell" />
            </router-link>
            <sup class="notifications_count">{{ notification_count }}</sup>
            <!-- <span class="pad_right_20 pointer d-none d-lg-inline-block"> <fa icon="calendar" /></span> -->
        </div>
        <div class="col-4"></div>
        <div class="col-4 text-right" style="padding-right: 300px">
            <!-- <span class="pad_right_20 pointer d-none d-lg-inline-block"><fa icon="comment" /></span>
            <span class="pad_right_20 pointer d-none d-lg-inline-block"><fa icon="gear" /></span> -->
        </div>
    </div>
</template>


<script>

import $ from "jquery";
import { useToast } from "vue-toastification";

export default {
    setup() {
        const toast = useToast()
        return { toast }
    },
    data() {
        return {
            user_name: '',
            user_email: '',
            show_notifications: false,
            notification_count: 0,

        }
    },
    methods: {
        log_out: function () {
            localStorage.removeItem("rent-user-ocl");
            window.location.href = "/login";
        },
        open_menu: function () {
            $(".nav_outer").toggle();
            if ($('.nav_outer').css('display') == 'block') {
                $(".content").css("margin-left", "250px");
                $(".Topbar").css("margin-left", "250px");
                $(".nav_inside").css("display", "block");
            } else {
                $(".content").css("margin-left", "0px");
                $(".nav_inside").css("display", "none");
                $(".Topbar").css("margin-left", "0px");
            }
        },
    },
    mounted() {
        let user_str = localStorage.getItem("rent-user-ocl");
        let user = JSON.parse(user_str);
        this.user_name = user.firstName + " " + user.lastName;
        this.user_email = user.email;
        var dom = this;
        $.ajax({
            type: "GET",
            url: this.api_url + "/notifications/getMyNotificationsCount",
            timeout: 0,
            dataType: "json",
            contentType: "application/json",
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            success: function (result) {
                if (result.code == 1) {
                    dom.notification_count = result.notifications;
                }
            },
            error: function (error) {
                dom.toast.warning("An error occured. ");
            }
        });
    },
}

</script>


<style>
.Topbar {
    position: fixed !important;
    top: 0;
    height: 50px;
    background-color: #040F57;
    box-shadow: 0 4px 8px 0 rgb(98 125 152 / 16%), 0 2px 4px -4px rgb(16 42 67 / 16%);
    width: 100%;
    z-index: 99;
    color: #fff;
}

.notifications_count {
    color: orange;
}

.notification_a:hover {
    text-decoration: none;
}
</style>
